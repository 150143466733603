import React from "react"
import ReactDynamicImport from "react-dynamic-import"
import { detectMob } from "@src/Helper"
import SEO from "@components/seo"

const loader = () => {
  if (!detectMob()) {
    return import("@components/ProductTour/ProductTour")
  } else {
    return import("@components/ProductTour/ProductTourMobile")
  }
}

const ProductTour = ReactDynamicImport({ loader })

const ProductTourPage = ({ location }) => {
  return (
    <>
      <SEO title="Product Tour" />
      <ProductTour location={location} />
    </>
  )
}

export default ProductTourPage
